<!-- 抢单规则 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'抢单规则':'抢单规则'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="90px">
      <el-form-item label="类型" prop="">
        <el-radio v-model="radio" label="1">事故救援</el-radio>
        <el-radio v-model="radio" label="2">高速救援</el-radio>
        <el-radio v-model="radio" label="3">非事故救援</el-radio>
      </el-form-item>
      <el-form-item label="项目" prop="">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="拖车"></el-checkbox>
          <el-checkbox label="搭电"></el-checkbox>
          <el-checkbox label="送油....."></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="群体" prop="">
        <el-checkbox-group v-model="checkList1">
          <el-checkbox label="企业司机"></el-checkbox>
          <el-checkbox label="共享司机"></el-checkbox>
          <el-checkbox label="调度"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="抢单顺序" prop="">
        <el-radio v-model="radio1" label="1">同时</el-radio>
        <el-radio v-model="radio1" label="2">按顺序</el-radio>
      </el-form-item>
      <el-form-item label="" prop="" v-if="radio1 == '2'">
        <div style="background: #F2F2F2;" class="paddingbiannju">
          <draggable v-model="list">
            <transition-group name="fade" tag="ul">
              <div v-for="(item, index) in list" :key="index">{{ item }}</div>
            </transition-group>
          </draggable>
        </div>
      </el-form-item>
      <el-form-item label="距离" prop="">
        <el-radio v-model="radio3" label="1">10公里发信息</el-radio>
        <el-radio v-model="radio3" label="2">20公里发信息</el-radio>
      </el-form-item>
      <el-form-item label="时间间隔" prop="">
        <el-input placeholder="请输入内容" v-model="form.input2" clearable>
          <template slot="append">分钟</template>
        </el-input>
      </el-form-item>
      <el-form-item label="优先级" prop="">
        <el-radio v-model="radio2" label="1">价格低优先</el-radio>
        <el-radio v-model="radio2" label="2">以距离近优先</el-radio>
      </el-form-item>
      <el-form-item label="启用" prop="">
        <el-radio v-model="radio4" label="1">是</el-radio>
        <el-radio v-model="radio4" label="2">否</el-radio>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        company_name:[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
        tax_number: [
          { required: true, message: '请输入开票税号', trigger: 'blur' },
          { min: 18, max: 18, message: '长度至少需要18个字符', trigger: 'blur' }
        ],
        deposit_bank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
        bank_number: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { min: 10, max: 20, message: '长度在10 到 20个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          // {
          //   pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          //   message: "请输入正确的电话号码",
          //   trigger: "blur"
          // }
          { min: 11, max: 11, message: '长度需要11个字符', trigger: 'blur' }
        ],
        enterprise_address: [
          { required: true, message: '请输入企业地址', trigger: 'blur' },
          { min: 2, max: 80, message: '长度在2 到 80个字符', trigger: 'blur' }
        ],
        delivery_address: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在2 到 50个字符', trigger: 'blur' }
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      radio:'',
      checkList:[],
      checkList1:[],
      radio1:'',

      list:['个体司机','企业司机','调度'],
      radio2:'',
      radio3:'',
      radio4:''

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.isUpdate === false){
            this.updateVisible(false);
            this.$emit('done');
          }else {
            this.updateVisible(false);
            this.$emit('done');
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
  .paddingbiannju{
    padding: 15px;
  }
</style>
