import { render, staticRenderFns } from "./dict-edit.vue?vue&type=template&id=100686b3&scoped=true&"
import script from "./dict-edit.vue?vue&type=script&lang=js&"
export * from "./dict-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100686b3",
  null
  
)

export default component.exports